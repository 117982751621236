// Core
import cx from "classnames";

// Components
import { Col, Grid, Row } from "antd";
import { Container } from "components/common/Container";
import { RetailerVideo } from "components/common/RetailerVideo";

// Utils
import st from "./Styles.module.less";
import { rowGutters } from "utils/constants";

export const IntroVideo = () => {
  const { md = true, lg = true } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;

  const videoStyles = cx(st.video, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });

  return (
    <section className={videoStyles} data-test-id="intro-video">
      <Container>
        <Row gutter={rowGutters}>
          <Col span={24}>
            <RetailerVideo />
          </Col>
        </Row>
      </Container>
    </section>
  );
};
