// Core
import { useTranslation } from "next-i18next";
import cx from "classnames";
import { Element } from "react-scroll";
import React from "react";

// Definition
import { ScrollEnum } from "data/common";

// Components
import { Row, Col, Grid } from "antd";
import { Container } from "components/common/Container";
import { LeadSignupRequest } from "components/common/LeadSignupRequest";
import { Image } from "components/ui/Image";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";

// Utils
import { rowGutters } from "utils/constants";
import { introInTouch } from "data/intro";
import st from "./Styles.module.less";

export const IntroInTouch = () => {
  const { t } = useTranslation(["intro"]);
  const { md, lg } = Grid.useBreakpoint();
  const { img, title, desc } = introInTouch;
  const isTablet = !lg;
  const isMobile = !md;

  const touchStyles = cx(st.touch, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });
  const contentStyles = cx(st.content, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });
  const touchMetaStyles = cx(st["touch-meta"], {
    [st.mobile]: isMobile,
  });
  const descriptionStyles = cx(st.desc, {
    [st.mobile]: isMobile,
    [st.tablet]: isTablet,
  });

  const titleJSX = t(title);

  const signupRequestJSX = (
    <Col xl={8} md={12} xs={24}>
      <LeadSignupRequest testId="intro-in-touch" type="signup" uniqKey="-in-touch-lead-create" />
    </Col>
  );

  const contentJSXDesktop = !isTablet && !isMobile && (
    <Row gutter={rowGutters} align="middle" justify="space-between">
      <Col xl={14} md={12}>
        <Row gutter={rowGutters} align="middle" justify="start">
          <Col xl={20}>
            <Title tag="h2" size="36" fontWeight="700" color="white-100" font="second">
              {titleJSX}
            </Title>
            <ul className={descriptionStyles}>
              {desc.map(({ item }) => (
                <li key={item} className={st["desc-item"]}>
                  <Text size="20" color="main-gray-3" fontWeight="400">
                    {t(item)}
                  </Text>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
      {signupRequestJSX}
    </Row>
  );

  const contentJSXTablet = isTablet && !isMobile && (
    <Row gutter={rowGutters} align="top" justify="space-between">
      <Col md={12} className={st["touch-meta-col-tablet"]}>
        <Row gutter={rowGutters}>
          <Col md={24}>
            <Title tag="h2" size="24" fontWeight="700" color="white-100" font="second">
              {titleJSX}
            </Title>
            <ul className={descriptionStyles}>
              {desc.map(({ item }) => (
                <li key={item} className={st["desc-item"]}>
                  <Text size="16" color="main-gray-3" fontWeight="400">
                    {t(item)}
                  </Text>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Col>
      {signupRequestJSX}
    </Row>
  );

  const contentJSXMobile = isMobile && (
    <Row gutter={rowGutters}>
      <Col xs={24}>
        <div className={touchMetaStyles}>
          <Row gutter={rowGutters}>
            <Col xs={24}>
              <Title
                tag="h2"
                size="18"
                color="white-100"
                font="second"
                fontWeight="700"
                alignmentHorizontal="center"
              >
                {titleJSX}
              </Title>
              <ul className={descriptionStyles}>
                {desc.map(({ item }) => (
                  <li key={item} className={st["desc-item"]}>
                    <Text size="16" color="main-gray-3" fontWeight="400">
                      {t(item)}
                    </Text>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </div>
      </Col>
      {signupRequestJSX}
    </Row>
  );

  return (
    <Element name={ScrollEnum.toIntroInTouch} className={touchStyles} data-test-id="intro-in-touch">
      <Image
        src={img.src}
        alt={t(img.alt)}
        layout="fill"
        objectFit="cover"
        objectPosition="center bottom"
        placeholder="blur"
      />
      <Container>
        <div className={contentStyles}>
          {contentJSXDesktop}
          {contentJSXTablet}
          {contentJSXMobile}
        </div>
      </Container>
    </Element>
  );
};
