// Core
import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import cx from "classnames";

// Definitions
import { SliderPropsRefFC } from "components/ui/Slider";

// Components
import { Row, Col, Grid } from "antd";
import Slick from "react-slick";
import { Image } from "components/ui/Image";
import { Button } from "components/ui/Button";
import { CarouselItem } from "components/common/CarouselItem";
import { Container } from "components/common/Container";
import { Icon } from "components/ui/Icon";
import { Title } from "components/ui/Typography/Title";

// Utils
import st from "./Styles.module.less";
import { introFeedback } from "data/intro";
import {
  rowGutters,
  contentSlickConfig,
  imageSlickConfig,
  breakpointScreen,
} from "utils/constants";

export const IntroFeedback = () => {
  const { t } = useTranslation(["intro"]);
  const { details, images, title } = introFeedback;
  const [Slider, setSlider] = useState<SliderPropsRefFC>();
  const [contentSlider, setContentSlider] = useState<Slick>();
  const [imageSlider, setImageSlider] = useState<Slick>();

  useEffect(() => {
    import("components/ui/Slider")
      .then((mod) => setSlider(mod.Slider))
      .catch((err) => {
        throw err;
      });
  }, []);

  const { md, lg } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;
  const isDesktop = !isTablet && !isMobile;

  const topSectionStyles = cx(st.top, {
    [st.mobile]: !md,
  });
  const contentStyles = cx(st.content, {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });
  const slideImageStyles = cx(st["carousel-img"], {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });

  const imageSliderConfig = {
    ...imageSlickConfig,
    adaptiveHeight: true,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: breakpointScreen.lg,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: breakpointScreen.md,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleContentSlider = (slider: Slick | null) => {
    if (slider) {
      setContentSlider(slider);
    }
  };

  const handleImageSlider = (slider: Slick | null) => {
    if (slider) {
      setImageSlider(slider);
    }
  };

  const handleBeforeChange = (_: number, nextIdx: number): void => {
    const maxIdx = images.length - 1;
    if (nextIdx === maxIdx) {
      imageSlider?.slickGoTo(nextIdx);
    } else {
      imageSlider?.slickNext();
    }
  };

  const onPrev = (): void => {
    contentSlider?.slickPrev();
    imageSlider?.slickPrev();
  };
  const onNext = (): void => {
    contentSlider?.slickNext();
    imageSlider?.slickNext();
  };

  return (
    <section className={st.feedback} data-test-id="intro-feedback">
      <div className={topSectionStyles}>
        <Container>
          <Row gutter={rowGutters} justify="space-between">
            <Col lg={8} md={12} xs={24}>
              <div className={contentStyles}>
                {isDesktop && (
                  <Title tag="h3" size="36" font="second" fontWeight="700" className={st.title}>
                    {t(title)}
                  </Title>
                )}
                {isTablet && !isMobile && (
                  <Title tag="h3" size="30" font="second" fontWeight="700" className={st.title}>
                    {t(title)}
                  </Title>
                )}
                {isMobile && (
                  <Title
                    alignmentHorizontal="center"
                    tag="h3"
                    size="18"
                    font="second"
                    fontWeight="700"
                    className={st["title-mobile"]}
                  >
                    {t(title)}
                  </Title>
                )}
                <div className={st.details}>
                  {Slider && (
                    <Slider
                      ref={handleContentSlider}
                      beforeChange={handleBeforeChange}
                      {...contentSlickConfig}
                    >
                      {details.map((item) => {
                        const localizedItem = {
                          ...item,
                          desc: t(item.desc),
                          author: {
                            ...item.author,
                            title: t(item.author.title),
                            desc: t(item.author.desc),
                          },
                        };
                        return (
                          <CarouselItem key={item.id} item={localizedItem} hasAuthor hasDesc />
                        );
                      })}
                    </Slider>
                  )}
                </div>

                {!isMobile && (
                  <div className={st["carousel-btns"]}>
                    <Button
                      className={st["carousel-btns-prev"]}
                      btnType="intro-navs"
                      type="primary"
                      size="large"
                      onClick={onPrev}
                    >
                      <Icon name="ShevronLeft" size="large" />
                    </Button>
                    <Button
                      className={st["carousel-btns-next"]}
                      btnType={"intro-navs"}
                      type="primary"
                      size="large"
                      onClick={onNext}
                    >
                      <Icon name="ShevronRight" size="large" />
                    </Button>
                  </div>
                )}
              </div>
            </Col>

            <Col lg={{ span: 14, offset: 2 }} md={12} xs={24}>
              <div className={st.images}>
                {Slider && (
                  <Slider ref={handleImageSlider} {...imageSliderConfig}>
                    {images.map((item) => {
                      return (
                        <div key={item.id} className={slideImageStyles}>
                          <Image
                            src={item.src}
                            alt={t(item.alt)}
                            placeholder="blur"
                            layout="fill"
                            objectFit="cover"
                          />
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
              {isMobile && (
                <div className={st["carousel-btns-mobile"]}>
                  <Button
                    className={st["carousel-btns-prev"]}
                    btnType={"intro-navs"}
                    type="primary"
                    size="large"
                    onClick={onPrev}
                  >
                    <Icon name="ShevronLeft" size="large" />
                  </Button>
                  <Button
                    className={st["carousel-btns-next"]}
                    btnType="intro-navs"
                    type="primary"
                    size="large"
                    onClick={onNext}
                  >
                    <Icon name="ShevronRight" size="large" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};
