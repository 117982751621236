// Core
import { useState, useEffect } from "react";
import cx from "classnames";
import { useTranslation } from "next-i18next";

// Definitions
import type { SliderPropsRefFC } from "components/ui/Slider";

// Components
import Slick from "react-slick";
import { Row, Col, Grid } from "antd";
import { Image } from "components/ui/Image";
import { Button } from "components/ui/Button";
import { CarouselItem } from "components/common/CarouselItem";
import { Container } from "components/common/Container";
import { Icon } from "components/ui/Icon";
import { Title } from "components/ui/Typography/Title";

// Utils
import st from "./Styles.module.less";
import { introAbout } from "data/intro";
import { contentSlickConfig, imageSlickConfig } from "utils/constants";

export const IntroAbout = () => {
  const { details, images, title } = introAbout;
  const { t } = useTranslation();
  const [Slider, setSlider] = useState<SliderPropsRefFC>();
  const [contentSlider, setContentSlider] = useState<Slick>();
  const [imageSlider, setImageSlider] = useState<Slick>();

  useEffect(() => {
    import("components/ui/Slider")
      .then((mod) => setSlider(mod.Slider))
      .catch((err) => {
        throw err;
      });
  }, []);

  const { md, lg } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;
  const isDesktop = !isTablet && !isMobile;

  const aboutStyles = cx(st.about, {
    [st.mobile]: !md,
  });
  const servicesStyles = cx(st.services, {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });
  const slideImageStyles = cx(st["carousel-img"], {
    [st.mobile]: !md,
    [st.tablet]: !lg,
  });
  const handleContentSlider = (slider: Slick | null) => {
    if (slider && !contentSlider) {
      setContentSlider(slider);
    }
  };

  const handleImageSlider = (slider: Slick | null) => {
    if (slider && !imageSlider) {
      setImageSlider(slider);
    }
  };

  const handleBeforeChange = (_: number, nextIdx: number) => {
    const maxIdx = images.length - 1;
    if (nextIdx === maxIdx) {
      imageSlider?.slickGoTo(nextIdx);
    } else {
      imageSlider?.slickNext();
    }
  };

  const onPrev = () => {
    contentSlider?.slickPrev();
    imageSlider?.slickPrev();
  };
  const onNext = () => {
    contentSlider?.slickNext();
    imageSlider?.slickNext();
  };

  return (
    <section className={aboutStyles} data-test-id="intro-about">
      <Container>
        <Row justify="space-between">
          <Col lg={8} md={12} xs={24}>
            <div className={servicesStyles}>
              {isDesktop && (
                <Title
                  tag="h3"
                  size="36"
                  font="second"
                  fontWeight="700"
                  className={st["services-title"]}
                >
                  {t(title)}
                </Title>
              )}
              {isTablet && !isMobile && (
                <Title
                  tag="h3"
                  size="30"
                  font="second"
                  fontWeight="700"
                  className={st["services-title"]}
                >
                  {t(title)}
                </Title>
              )}
              {isMobile && (
                <Title
                  alignmentHorizontal="center"
                  tag="h3"
                  size="18"
                  font="second"
                  fontWeight="700"
                  className={st["services-title-mobile"]}
                >
                  {t(title)}
                </Title>
              )}
              <div className={st.details}>
                {Slider && (
                  <Slider
                    ref={handleContentSlider}
                    beforeChange={handleBeforeChange}
                    {...contentSlickConfig}
                  >
                    {details.map((item) => {
                      const localizedItem = {
                        ...item,
                        title: t(item.title),
                      };

                      return <CarouselItem key={item.id} item={localizedItem} />;
                    })}
                  </Slider>
                )}
                {!isMobile && (
                  <div className={st["carousel-btns"]}>
                    <Button
                      className={st["carousel-btns-prev"]}
                      btnType={"intro-navs"}
                      type="primary"
                      size="large"
                      onClick={onPrev}
                    >
                      <Icon name="ShevronLeft" size="large" />
                    </Button>
                    <Button
                      className={st["carousel-btns-next"]}
                      btnType={"intro-navs"}
                      type="primary"
                      size="large"
                      onClick={onNext}
                    >
                      <Icon name="ShevronRight" size="large" />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Col>

          <Col lg={{ span: 14, offset: 2 }} md={12} xs={24}>
            <div className={st.images}>
              {Slider && (
                <Slider ref={handleImageSlider} {...imageSlickConfig}>
                  {images.map((item) => {
                    return (
                      <div key={item.id} className={slideImageStyles}>
                        <Image
                          src={item.src}
                          alt={t(item.alt)}
                          placeholder="blur"
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
            {isMobile && (
              <div className={st["carousel-btns-mobile"]}>
                <Button
                  className={st["carousel-btns-prev"]}
                  btnType={"intro-navs"}
                  type="primary"
                  size="large"
                  onClick={onPrev}
                >
                  <Icon name="ShevronLeft" size="large" />
                </Button>
                <Button
                  className={st["carousel-btns-next"]}
                  btnType={"intro-navs"}
                  type="primary"
                  size="large"
                  onClick={onNext}
                >
                  <Icon name="ShevronRight" size="large" />
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
