// Core
import cx from "classnames";

// Components
import { Grid } from "antd";
import { Title } from "components/ui/Typography/Title";
import { Text } from "components/ui/Typography/Text";
import { List } from "components/ui/List";

// Utils
import st from "./Styles.module.less";

type CarouselItemProps = {
  hasAuthor?: boolean;
  hasDesc?: boolean;
  item: { title?: string; desc?: string; author?: { title: string; desc: string } };
};

export const CarouselItem = (props: CarouselItemProps) => {
  const { hasAuthor = false, hasDesc = false, item } = props;
  const { md, lg } = Grid.useBreakpoint();
  const isTablet = !lg;
  const isMobile = !md;
  const isDesktop = !isTablet && !isMobile;

  const itemStyles = cx(st["list-item"], {
    [st.mobile]: isMobile,
  });
  const itemMetaStyles = cx(st["list-item-meta"], {
    [st.mobile]: isMobile,
  });

  return (
    <List.Item className={itemStyles}>
      <>
        {isDesktop && (
          <>
            {item.title ? (
              <Title
                tag="h4"
                size="36"
                color="orange-10"
                fontWeight="700"
                font="second"
                className={st["list-item-header"]}
              >
                {item.title}
              </Title>
            ) : null}
            {hasDesc && item.desc && (
              <Text tag="p" size="16" color="gray-700" className={st["list-item-desc"]}>
                {item.desc}
              </Text>
            )}
          </>
        )}

        {isTablet && !isMobile && (
          <>
            {item.title ? (
              <Title
                tag="h4"
                size="30"
                color="orange-10"
                fontWeight="700"
                font="second"
                className={st["list-item-header-tablet"]}
              >
                {item.title}
              </Title>
            ) : null}
            {hasDesc && item.desc && (
              <Text tag="p" size="14" color="gray-700" className={st["list-item-desc-tablet"]}>
                {item.desc}
              </Text>
            )}
          </>
        )}

        {isMobile && (
          <>
            {item.title ? (
              <Title
                tag="h4"
                size="18"
                color="orange-10"
                fontWeight="700"
                font="second"
                className={st["list-item-header-mobile"]}
                alignmentHorizontal="center"
              >
                {item.title}
              </Title>
            ) : null}
            {hasDesc && item.desc && (
              <Text tag="p" size="14" color="gray-700" className={st["list-item-desc-mobile"]}>
                {item.desc}
              </Text>
            )}
          </>
        )}
        {hasAuthor && item.author ? (
          <List.Item.Meta
            title={item.author.title}
            description={item.author.desc}
            className={itemMetaStyles}
          />
        ) : null}
      </>
    </List.Item>
  );
};
