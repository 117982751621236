// Core
import cx from "classnames";

// Components
import { Col, Grid, Row } from "antd";
import { Image } from "components/ui/Image";

// Definitions
import type { ReactElement } from "react";
import type { ImageType } from "components/ui/Image/Image.types";

// Utils
import { rowGutters } from "utils/constants";
import st from "./Styles.module.less";

type RetailerBrandsProps = {
  items?: ImageType[];
};

export const RetailerBrands = ({ items = [] }: RetailerBrandsProps): ReactElement | null => {
  const { md, lg } = Grid.useBreakpoint();
  const brandsStyles = cx(st.brands, {
    [st.mobile]: !md,
  });
  const listStyles = cx(st.list, {
    [st.mobile]: !md,
  });
  const itemStyles = cx(st.item, {
    [st.tablet]: !lg,
    [st.mobile]: !md,
  });

  const isTablet = !lg;
  const isMobile = !md;

  const brands = isTablet && !isMobile ? items.slice(0, Math.floor(items.length / 2)) : items;

  return !items.length ? null : (
    <div className={brandsStyles}>
      <Row justify="center" gutter={rowGutters} className={listStyles}>
        {brands.map((brand, index) => (
          <Col lg={3} md={6} key={index} className={itemStyles}>
            <Image src={brand.src} alt={brand.alt} width={92} height={60} unoptimized />
          </Col>
        ))}
      </Row>
    </div>
  );
};
